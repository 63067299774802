import { gql } from '@apollo/client';

export const CheckoutPageFragment = gql`
    fragment CheckoutPageFragment on Cart {
        id
        email
        applied_coupons {
            code
        }
        selected_payment_method {
            code
            title
        }
        shipping_addresses {
            selected_shipping_method {
                carrier_title
                method_title
                amount {
                    currency
                    value
                }
            }
            street
        }
        total_quantity
        available_payment_methods {
            code
        }
    }
`;

export const CheckoutTotalFragment = gql`
    fragment CheckoutTotalFragment on Cart {
        total_quantity
        prices {
            grand_total {
                currency
                value
            }
        }
    }
`;
