import { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { clearCartDataFromCache } from '@/lib/store/utils/clearCartDataFromCache';
import { useCartContext, useUserContext } from '@/lib/context';
import operations from '@/components/CheckoutPage/api/checkoutPage.gql';

interface IUseCleanUpCart {
    cleanUpCart: () => void;
}

export const useCleanUpCart = (): IUseCleanUpCart => {
    const [{ cartId }, { createCart, removeCart }] = useCartContext();
    const [{ isSignedIn }] = useUserContext();
    const { createCartMutation, deactivateCartMutation } = operations;
    const [fetchCartId] = useMutation(createCartMutation);
    const [deactivateCartId] = useMutation(deactivateCartMutation);
    const apolloClient = useApolloClient();

    const cleanUpCart = useCallback(async () => {
        if (isSignedIn && cartId) {
            await deactivateCartId({
                variables: {
                    cartId,
                },
            });
        }

        await removeCart();
        await clearCartDataFromCache(apolloClient);
        await createCart({
            fetchCartId,
        });
    }, [apolloClient, createCart, fetchCartId, removeCart]);

    return {
        cleanUpCart,
    };
};
